<template>
  <v-row
    class="px-10"
    dense
  >
    <v-col
      cols="12"
      md="12"
      dense
    >
      <v-icon
        small
        color="primary"
        >fas fa-exclamation-triangle</v-icon
      >
      É necessário pelo menos <strong>um</strong> contato preenchido no
      cadastro.
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <ContactsCard
        v-for="(contact, idx) in client.client_contact"
        :key="idx"
        :contact="contact"
        :contact_idx="idx"
        @remove="removeContact"
      />
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <v-btn
        @click="addContact"
        class="primary"
        icon
        dark
      >
        <v-icon small>fas fa-plus</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    client: Object,
  },
  components: {
    ContactsCard: () => import('./ContactsCard'),
  },
  methods: {
    addContact() {
      this.client.client_contact.push({})
    },
    removeContact(contact) {
      if (this.client.client_contact.length > 1) {
        let idx = this.client.client_contact.indexOf(contact)
        this.client.client_contact.splice(idx, 1)
      }
    },
  },
}
</script>
